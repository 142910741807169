const zones = [
  'Etc/GMT+12',
  'Pacific/Pago_Pago',
  'Pacific/Midway',
  'Pacific/Honolulu',
  'America/Juneau',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Phoenix',
  'America/Chihuahua',
  'America/Mazatlan',
  'America/Denver',
  'America/Guatemala',
  'America/Chicago',
  'America/Mexico_City',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Regina',
  'America/Bogota',
  'America/New_York',
  'America/Indiana/Indianapolis',
  'America/Lima',
  'America/Lima',
  'America/Halifax',
  'America/Caracas',
  'America/Guyana',
  'America/La_Paz',
  'America/Puerto_Rico',
  'America/Santiago',
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Godthab',
  'America/Montevideo',
  'Atlantic/South_Georgia',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Africa/Casablanca',
  'Europe/Dublin',
  'Europe/London',
  'Europe/Lisbon',
  'Europe/London',
  'Africa/Monrovia',
  'Etc/UTC',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Zurich',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Budapest',
  'Europe/Copenhagen',
  'Europe/Ljubljana',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Rome',
  'Europe/Sarajevo',
  'Europe/Skopje',
  'Europe/Stockholm',
  'Europe/Vienna',
  'Europe/Warsaw',
  'Africa/Algiers',
  'Europe/Zagreb',
  'Europe/Zurich',
  'Europe/Athens',
  'Europe/Bucharest',
  'Africa/Cairo',
  'Africa/Harare',
  'Europe/Helsinki',
  'Asia/Jerusalem',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Africa/Johannesburg',
  'Europe/Riga',
  'Europe/Sofia',
  'Europe/Tallinn',
  'Europe/Vilnius',
  'Asia/Baghdad',
  'Europe/Istanbul',
  'Asia/Kuwait',
  'Europe/Minsk',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Riyadh',
  'Europe/Moscow',
  'Asia/Tehran',
  'Asia/Muscat',
  'Asia/Baku',
  'Asia/Muscat',
  'Europe/Samara',
  'Asia/Tbilisi',
  'Europe/Volgograd',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Karachi',
  'Asia/Tashkent',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Kolkata',
  'Asia/Colombo',
  'Asia/Kathmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Dhaka',
  'Asia/Urumqi',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Bangkok',
  'Asia/Jakarta',
  'Asia/Krasnoyarsk',
  'Asia/Novosibirsk',
  'Asia/Shanghai',
  'Asia/Chongqing',
  'Asia/Hong_Kong',
  'Asia/Irkutsk',
  'Asia/Kuala_Lumpur',
  'Australia/Perth',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Asia/Tokyo',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Melbourne',
  'Pacific/Guam',
  'Australia/Hobart',
  'Australia/Melbourne',
  'Pacific/Port_Moresby',
  'Australia/Sydney',
  'Asia/Vladivostok',
  'Asia/Magadan',
  'Pacific/Noumea',
  'Pacific/Guadalcanal',
  'Asia/Srednekolymsk',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Asia/Kamchatka',
  'Pacific/Majuro',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Tongatapu',
  'Pacific/Apia',
  'Pacific/Fakaofo'
]

export default zones
